<template>
  <div class="readme-article">
    <h1 id="用户">四、用户</h1>
    <h2 id="用户列表">1.用户列表</h2>
    <p>1.1查看员工列表，显示员工姓名、所属权限组、在线状态等信息，支持条件查询和查看员工详情。</p>
    <p><img src="@/assets/img/call/4-1.png" /></p>
    <p>1.2员工详情可以查看和编辑员工信息。</p>
    <p><img src="@/assets/img/call/4-2.png" /></p>
    <p>1.3员工详情上方可切换至云呼叫中心坐席，配置好坐席账号密码和接口数据该员工就可以正常使用外呼了。</p>
    <p><img src="@/assets/img/call/4-3.png" /></p>
    <h2 id="添加用户">2.添加用户</h2>
    <p>管理员手动添加用户名，姓名、密码、手机号码，所属权限组，状态是必填项，完善其他信息后保存。</p>
    <p><img src="@/assets/img/call/4-4.png" /></p>
    <h2 id="用户组与权限">3.用户组与权限</h2>
    <p>查看用户组权限列表，显示用户组名称，权限等级，点击权限设置可编辑权限。</p>
    <p><img src="@/assets/img/call/4-5.png" /></p>
    <h2 id="业务人员分组设置">4.业务人员分组设置</h2>
    <p>查看业务人员分组信息，显示名称、状态、每日分配上限、待处理上限、应用目标等信息，可编辑修改。</p>
    <p><img src="@/assets/img/call/4-6.png" /></p>
    <h2 id="业务人员等级">5.业务人员等级</h2>
    <p>查看业务人员等级列表，显示等级名称、权重、应用目标等信息，可编辑修改。</p>
    <p><img src="@/assets/img/call/4-7.png" /></p>
    <h2 id="业务专员">6.业务专员</h2>
    <p>查看业务专员列表，展示业务专员基本信息，可查看业务专员详情。</p>
    <p><img src="@/assets/img/call/4-8.png" /></p>
    <h2 id="我的资料">7.我的资料</h2>
    <p>查看我的资料，可编辑修改。</p>
    <p><img src="@/assets/img/call/4-9.png" /></p>
    <h2 id="修改密码">8.修改密码</h2>
    <p>修改当前账号密码，需填写旧密码。</p>
    <p><img src="@/assets/img/call/4-10.png" /></p>
  </div>
</template>

<script>
export default {
  name: "call4-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>